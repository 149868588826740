import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import environment, { clinicBaseUrl } from "../../environments/environment";
import {
  IMAGE_LIST,
  DOWNLOAD_FILE,
  UPDATE_FILE,
  REQUEST_CANCEL,
} from "./images.types";
import {
  getImageListResponse,
  downloadFileResponse,
  updateFileResponse,
} from "./images.actions";

const ImagesEpic = {};

ImagesEpic.getImageList = (action$) =>
  action$.pipe(
    ofType(IMAGE_LIST),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          applicationId: 13144,
          userId: 600,
          userName: "test",
        },

        url: `${clinicBaseUrl}/trillium-internal-service/v1/image/list/all?clinicId=${action.payload.clinicId}&start=${action.payload.start}&limit=${action.payload.limit}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getImageListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ImagesEpic.downloadFile = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD_FILE),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "Content-Type": "application/octet-stream",
        },
        // url: `${clinicBaseUrl}/trillium-provider-service/v1/image/file/download?fileId=${action.payload.fileId}&fileName=${action.payload.fileName}&image_type=${action.payload.imageType}`,
        url: `${clinicBaseUrl}/trillium-provider-service/v1/file/download?imageId=${action.payload?.fileId}&patientId=${action.payload?.patientId}`,
        method: "GET",
        responseType: "blob",
      }).pipe(
        map(
          (response) => downloadFileResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ImagesEpic.updateFile = (action$) =>
  action$.pipe(
    ofType(UPDATE_FILE),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/image/list`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => updateFileResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default ImagesEpic;
